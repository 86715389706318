import { autoinject } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';
import Highcharts from 'highcharts';
import moment from 'moment';
import { AlertStatsPerEntitiesSchemaItems, StatsApiClient, VesselStats } from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { IDateRange } from '../../range-filter/range-filter';
import { BusinessMap } from './../../../../services/cyber-api';

@autoinject
export class RiskChart {
    @bindable() private businessMap: BusinessMap;
    @bindable() private dateRange: IDateRange;
    private mappedData: any;
    private series: Highcharts.SeriesLineOptions[];
    private options: Highcharts.Options = {
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                },
                // Specifies the line colors up to certain thresholds
                zones: [{
                    value: 0, // Up to 20
                    color: Utilities.getRiskColor(0)
                }, {
                    value: 33, // Up to 33
                    color: Utilities.getRiskColor(33)
                }, {
                    value: 66, // Up to 66
                    color: Utilities.getRiskColor(66)
                }, {
                    // Anything over 66
                    color: Utilities.getRiskColor(67)
                }]
            }
        },
        legend: {
            enabled: false
        },
        yAxis: {
            title: null,
            labels: {
                format: '{value}',
                step: 1
            },
            tickInterval: 20, // A label every 20
            min: 0,
            max: 100
        }
    };

    constructor(
        private state: StateApi,
        private statsApi: StatsApiClient
    ) { }

    public async refresh(): Promise<void> {
        if (!this.businessMap) return;

        const data = await this.retrieveData();
        if (data) {
            this.mappedData = this.mapData(data);
            this.series = this.filterSeries(this.mappedData);
        } else
            this.series = [];
    }

    private async businessMapChanged(newValue, oldValue): Promise<void> {
        // Trigger an entire refresh when the businessMap has changed as the current data is no longer valid
        await this.refresh();
    }

    private dateRangeChanged(newValue, oldValue): void {
        // only if the previous value was unset, we know the user has changed it manually
        if (oldValue === undefined) return;

        // Reset the series and re-filter the data.
        this.series = [];
        if (this.mappedData)
            this.series = this.filterSeries(this.mappedData);
    }

    private async retrieveData(): Promise<VesselStats[]> {
        // Set fixed from/until dates to always fetch a 30-day range to provide a somewhat consistent dataset
        const from = moment().subtract(30, 'days').startOf('day').toDate();
        const until = moment().endOf('day').toDate();

        try {
            // Retrieve data for a single vessel
            return await this.statsApi.vesselRiskHistory(this.businessMap.ttSiteId, this.state.company(), from, until);
        } catch (error) {
            Toast.statsApiError();
            throw error;
        }
    }

    private mapData(data: VesselStats[]): Highcharts.SeriesLineOptions[] {
        // Extract the x (dates) and y (risk) values

        let mapped = [];
        data.forEach((value, i) => {
            const formattedAndRoundedRisk = parseFloat(Utilities.formatNumber(value.risk, 0));
            const tooltip = `${value.time.toDateString()}<br>Events analyzed: ${value.eventCountersTotal}<br>`;
            const record = { x: moment(new Date(value.time)).valueOf(), y: formattedAndRoundedRisk, name: tooltip };
            mapped.push(record);
        });

        // Add some special ui-sauce to the last point
        mapped = this.replaceLastPoint(mapped);

        return mapped;
    }

    private replaceLastPoint(mapped: any[]) {
        // Extract and *remove* last item from collection
        const original = mapped.pop();
        if (!original) return mapped;

        // Add a sweet, special last point that "sticks out"
        const lastPoint = {
            x: original.x,
            y: original.y,

            // Style only the last series point
            marker: {
                enabled: true,
                radius: 7,
                fillColor: 'white',
                lineWidth: 3,
                lineColor: Utilities.getRiskColor(original.y)
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '1.2rem',
                    color: Utilities.getRiskColor(original.y)
                }
            },
            name: original.name
        };

        mapped.push(lastPoint);
        return mapped;
    }

    private filterSeries(data: any[]): Highcharts.SeriesLineOptions[] {
        return [
            {
                name: 'Risk level',
                type: 'line',
                data: data
                    .filter((item) =>
                        item.x >= this.dateRange.from.startOf('day').valueOf()
                        && item.x <= this.dateRange.until.endOf('day').valueOf()
                    )
            }
        ];
    }
}
